<template>
  <div class="english-wrap">
    <div class="english-wrap-title">
      Subscription Benefits
    </div>
    <div class="english-wrap-content">
<!--      <div class="english-wrap-content-user" v-if="memberInfo.id">-->
<!--        <div class="name">-->
<!--          <div class="name-text" v-if="memberInfo.mobile">{{ memberInfo.mobile }}</div>-->
<!--          <div class="name-text" v-else-if="memberInfo.email">{{ memberInfo.email }}</div>-->
<!--          <div class="member-tag vip-tag" v-if="memberInfo.vip_level">VIP Member</div>-->
<!--        </div>-->
<!--        <div class="tip" v-if="memberInfo.vip_level">-->
<!--          <span>Membership expiration date: {{ $moment(memberInfo.vip_expire).format('YYYY-MM-DD') }}</span>-->
<!--        </div>-->
<!--        <div class="tip" v-else>-->
<!--          <span>Please click the Subscribe button below to become a VIP Member</span>-->
<!--        </div>-->
<!--      </div>-->
      <div class="english-wrap-content-list">
        <div class="english-wrap-content-list-cell" v-for="(item, index) in tempTabList" :key="index">
          <div class="english-wrap-content-list-cell-header">
            {{ chineseToEnglish(item.name, true) }} Subscription For ${{ item.usd_price }}
          </div>
          <div class="english-wrap-content-list-cell-content">
            <div class="english-wrap-content-list-cell-content-tip">
              Gain unlimited access to exclusive reports and expert commentaries for a {{ chineseToEnglish(item.name, false) }}
            </div>
            <div class="english-wrap-content-list-cell-content-btn" @click="subscribe(item.obj, item.id)">Subscribe</div>
          </div>
        </div>
      </div>
      <div class="english-wrap-content-tip">
        For inquiries about institutional subscriptions, please write to
        <a :href="'mailto:'+companyEmail" class="email" v-if="companyEmail">{{companyEmail}}</a>
      </div>
    </div>
    <!-- 选择项目进行支付 -->
    <english-project ref="englishProjectRef"></english-project>
  </div>
</template>

<script>
import { memberLevelList, initConfig, userInfo } from "@/api";
import EnglishProject from "../component/englishProject";
export default {
  name: "EnglishWrap",
  components: {
    EnglishProject
  },
  data() {
    return {
      loading: false,
      // 权益列表
      tabList: [],
      // 模板循环权益列表
      tempTabList: [],
      // 企业邮箱
      companyEmail: "",
      // 用户信息
      memberInfo: {}
    }
  },
  created() {
    this.getUserInfo();
    this.getInitConfig();
    this.getMemberLevelList();
  },
  methods: {
    // 获取会员类型列表
    async getMemberLevelList() {
      this.loading = true;
      let res = await memberLevelList({search: "id:4|status:1", searchFields: "id:=|status:=", orderBy: "id", sortedBy: "asc" }, true);
      if (res && res.code == 200) {
        this.tabList = res.data.list;
        this.levelHandle(res.data.list);
      }
      this.loading = false;
    },
    // 权益数据处理
    levelHandle(rows) {
      if (rows && rows.length > 0) {
        let arr = [];
        for (let i = 0; i < rows.length; i++) {
          let item = rows[i];
          if (item.user_level_details && item.user_level_details.length > 0) {
            for (let j = 0; j < item.user_level_details.length; j++) {
              let jtem = item.user_level_details[j];
              jtem['obj'] = item;
              arr.push(jtem);
            }
          }
        }
        this.tempTabList = arr;
      }
    },
    // 获取配置信息
    async getInitConfig() {
      let res = await initConfig();
      if (res && res.code == 200) {
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item.config_key == "EMAIL") {
              this.companyEmail = item.config_value;
            }
          }
        }
      }
    },
    // 将中文转为英文
    chineseToEnglish(str, type) {
      let temp_str = "";
      if (str) {
        temp_str = str[str.length - 1];
        if (temp_str == "年") {
          temp_str = "year";
        } else if (temp_str == "月") {
          temp_str = "month";
        } else if (temp_str == "天") {
          temp_str = "day";
        }
        if (type) {
          return `${str[0]}-${temp_str.replace(/^\w/, letter => letter.toUpperCase())}`
        }
      }
      return temp_str;
    },
    // 获取用户信息
    async getUserInfo() {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        let res = await userInfo({}, true);
        if (res && res.code == 200) {
          this.memberInfo = res.data.user_info;
        }
      }
    },
    // 开始订阅
    subscribe(row, levelId) {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        this.$refs.englishProjectRef.open(row, levelId);
      } else {
        this.$emit("login");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.english-wrap {
  max-width: 50%;
  margin: 0 auto;
  font-family: Georgia;
  &-title {
    font-size: 30px;
    text-align: center;
    font-family: Georgia_Bold;
    margin-top: 80px;
  }
  &-content {
    margin-top: 80px;
    min-height: 600px;
    &-user {
      display: flex;
      margin-left: 12%;
      margin-bottom: 20px;
      .name {
        display: flex;
        margin-right: 15px;
      }
      .member-tag {
        font-size: 12px;
        font-weight: 500;
        padding: 3px 10px;
        border-radius: 5px;
        margin-left: 10px;
      }
      .vip-tag {
        color: #ffffff;
        background-color: #ed6f33;
      }
      .ordinary-tag {
        color: #ffffff;
        background-color: #d9d9d9;
      }
    }
    &-list {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      &-cell {
        flex: 1;
        margin-top: 30px;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        border: 1px solid #999999;
        &:last-child {
          margin-right: 0;
        }
        &-header {
          font-family: Georgia_Bold;
          font-size: 16px;
          padding: 15px 30px;
          text-align: center;
          background-color: #d9d9d9;
        }
        &-content {
          flex: 1;
          padding: 30px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          &-tip {
            flex: 1;
            text-align: center;
            line-height: 1.5;
            font-size: 14px;
            padding: 30px 0;
          }
          &-btn {
            cursor: pointer;
            user-select: none;
            color: #ffffff;
            text-align: center;
            padding: 10px 30px;
            font-size: 16px;
            font-family: Georgia_Bold;
            background-color: #ed6f33;
            display: inline-block;
            &:hover, &:active {
              background-color: #f28551;
            }
          }
        }
      }
    }
    &-tip {
      font-size: 18px;
      margin-top: 80px;
      .email {
        color: #ed6f33;
        font-family: Georgia_Bold;
        text-decoration: none;
        display: inline-block;
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .english-wrap {
    &-title {
      margin-top: 50px;
      font-size: 26px;
    }
    &-content {
      margin-top: 50px;
      &-list {
        padding: 0 30px;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .english-wrap {
    &-title {
      margin-top: 50px;
      font-size: 26px;
    }
    &-content {
      margin-top: 50px;
      &-user {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        margin-bottom: 20px;
        .name {
          flex-direction: column;
          align-items: center;
        }
        .member-tag {
          margin-top: 10px;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 5px;
          margin-left: 10px;
        }
        .tip {
          margin-top: 10px;
        }
        .vip-tag {
          color: #ffffff;
          background-color: #ed6f33;
          display: inline-block;
        }
        .ordinary-tag {
          color: #ffffff;
          background-color: #d9d9d9;
          display: inline-block;
        }
      }
      &-list {
        width: calc(100% - 60px);
        padding: 0 30px;
        flex-direction: column;
        &-cell {
          width: 100%;
          margin-right: 0;
        }
      }
      &-tip {
        line-height: 1.6;
        margin-top: 0;
        padding: 30px;
      }
    }
  }
}
</style>